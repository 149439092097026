import axios from '@/libs/axios'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import { saveAs } from 'file-saver';
import moment from "moment"

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    smses: [],
    total: 0,
    sms: {},
    smsInfo: {}
  },
  getters: {
    getSmses: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'createdAt',
        sortDesc = true,
        status = null,
        type = null,
        variant = null,
        range = null,
      } = params

      // console.log(q)

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      const queryLowered = q.toLowerCase()
      const filteredData = state.smses.filter(
        sms =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
            sms._contacts.find(c =>  c.name.toLowerCase().includes(queryLowered) ) || sms._groups.find(g => g.name.toLowerCase().includes(queryLowered)) || sms.message.toLowerCase().includes(queryLowered) || sms._scheduled.toLowerCase().includes(queryLowered) || sms._createdAt.toLowerCase().includes(queryLowered)
          ) &&

          //  
          sms.type === (type || sms.type) &&
          sms.variant === (variant || sms.variant) &&
          sms.status === (status || sms.status) &&
          sms.createdAt.includes(getRange(range) || sms.createdAt) ,
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      return {
          smses: paginateArray(sortedData, perPage, page),
          total: filteredData.length,
      }
      
    },
    getAllSmses: state => {
      return {
        smses: state.smses,
        total: state.smses.length,
      }
    },
    getSms: state => {
      return state.sms
    },
    getSmsInfo: state => {
      return state.smsInfo
    }
  },
  mutations: {
    updateSmses:  (state, smses) => {
      //
      state.smses = smses
      state.total = smses.length
    },
    updateSms: (state, sms) => {
      //
      state.sms = sms
    },
    updateSmsInfo:  (state, info) => {
      //
      state.smsInfo = info
    },
  },
  actions: {
    fetchSmses({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/church/sms/'+userData.church)
          .then(response => {

            //
            commit('updateSmses', response.data.filter(re => userData.role !=="admin" ? re.by == userData.fullName : true))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updateSmses', [])
            
            reject(error)

          })
      })
    },
    fetchSms({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/church/sms/${userData.church}/${id}`)
            .then(response => { 

              //
              commit('updateSms', response.data)
              
              resolve(response) 

            })
            .catch(error => {
              
              // 
              commit('updateSms', {})

              reject(error)
            
          })
      })
    },
    churchInfo(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/church/${userData.church}`)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
          })
      })
    },
    fetchSmsInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/church/sms/sender/${userData.church}`)
          .then(response => {

              //
              commit('updateSmsInfo', response.data)
              
              resolve(response)
          })
          .catch(error => {

            commit('updateSms', {})
            
            reject(error)
          })
      })
    },
    addSms(ctx, { sms }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/church/sms', { ...sms, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSms(ctx, { sms } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/church/sms/${userData.church}/${sms.id}`, { ...sms, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelSms(ctx, { sms } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/church/sms/cancel/${userData.church}/${sms.id}`, { ...sms, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delSms(ctx, { sms }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/church/sms/${userData.church}/${sms.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genSmsReport(ctx, memberData) {
      return new Promise((resolve, reject) => {

        const fileName = 'Sms_Report_'+(new Date().toISOString().substring(0,10))+'_'+new Date().getTime()

          axios.post(`/church/sms/report/${userData.church}`, {
            fileName: fileName
          }, {
              responseType: 'blob'
          }).then((response) => {
              //
              saveAs(response.data, fileName);
          });
      })
    },
  },
}
