<template>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ userLocal.id ? 'Update': 'Add' }} Group
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
  
            <!-- Group -->
            <validation-provider
              #default="validationContext"
              name="Group"
              rules="required"
            >
              <b-form-group
                label="Group"
                label-for="group"
              >
                <b-form-input
                  id="group"
                  v-model="userLocal.group"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="New Group"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Mission -->
            <b-form-group
                label="Mission"
                label-for="mission"
              >
                <b-form-input
                  id="mission"
                  v-model="userLocal.mission"
                  placeholder="Mission"
                  trim
                />
            </b-form-group>

             <!-- Description -->
             <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="userLocal.description"
                  trim
                  placeholder="Description"
                >
                </b-form-textarea>
            </b-form-group>
            
            <!-- Members -->
            <b-form-group
                label="Add Members"
                label-for="members"
              >
                <v-select
                  v-model="userLocal.members"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="getAllMembers.map(m=>({ member: m.fullName, memID: m.memID, contact1: m.contact1 }))"
                  label="member"
                  multiple
                  :close-on-select="false"
                  input-id="members"
                />
                <template #option="{ memID, member }">
                  <!-- <b-avatar
                    size="sm"
                    :src="avatar"
                  /> -->
                  <span> {{ memID }}</span>
                  <span class="ml-50 align-middle"> {{ member }}</span>
                </template>

                <template #selected-option="{  member }">
                  <!-- <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  /> -->
                  <span class="ml-50 align-middle"> {{ member }}</span>
                </template>
            </b-form-group>
            
            <!-- Username -->
            <!-- <validation-provider
              #default="validationContext"
              name="Username"
              rules="required|alpha-num"
            >
              <b-form-group
                label="Username"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="userData.username"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- Email -->
            <!-- <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- Company -->
            <!-- <validation-provider
              #default="validationContext"
              name="Contact"
              rules="required"
            >
              <b-form-group
                label="Contact"
                label-for="contact"
              >
                <b-form-input
                  id="contact"
                  v-model="userData.contact"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- Company -->
            <!-- <validation-provider
              #default="validationContext"
              name="Company"
              rules="required"
            >
              <b-form-group
                label="Company"
                label-for="company"
              >
                <b-form-input
                  id="company"
                  v-model="userData.company"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- Country -->
            <!-- <validation-provider
              #default="validationContext"
              name="Country"
              rules="required"
            >
              <b-form-group
                label="Country"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries"
                  :clearable="false"
                  input-id="country"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- User Role -->
            <!-- <validation-provider
              #default="validationContext"
              name="User Role"
              rules="required"
            >
              <b-form-group
                label="User Role"
                label-for="user-role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- Plan -->
            <!-- <validation-provider
              #default="validationContext"
              name="Plan"
              rules="required"
            >
              <b-form-group
                label="Plan"
                label-for="plan"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.currentPlan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="planOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="plan"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,toRefs } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import useMembersList from '../../membership/members-list/useMemberList'
  import useGroupsListAddNew from './useGroupListAddNew'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewUserSidebarActive',
      event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
      isAddNewUserSidebarActive: {
        type: Boolean,
        required: true,
      },
      userData: {
        type: Object
      },
      clearUserData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
    setup(props, { emit }) {

      
      const clearFormData = ref(null)
      

      const { 
        userLocal,
        resetUserLocal,
        onSubmit, 
      
      } = useGroupsListAddNew(toRefs(props), clearFormData, emit)

      const { getAllMembers } = useMembersList()
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetUserLocal, props.clearUserData)

      clearFormData.value = clearForm
  
      return {
        userLocal,
        onSubmit,
        
        resetForm,
        refFormObserver,
        getValidationState,
        getAllMembers
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  