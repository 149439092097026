<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <group-list-add-new
      v-model="isAddNewMemberSidebarActive"
      :userData="userData"
      :clear-user-data="clearUserData"
      @add-group="addGroup"
      @update-group="updateGroup"
    />

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="8"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="isAddNewMemberSidebarActive = true"
            class="mr-1"
          >
            Add Record
          </b-button>

          <!-- <b-button
            variant="outline-primary"
            @click="isAddNewMemberSidebarActive = true"
          >
            Appointment
          </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <!-- <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select> -->
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refGroupListTable"
      :items="getGroups"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <!-- <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        > -->
          <!-- #{{ data.value }} -->
          {{ data.index+1 }}
        <!-- </b-link> -->
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>


      <!-- Column: group -->
      <template #cell(group)="data">
        
        <span
          class="font-weight-bold"
        >
          {{ data.item.group }}
      </span>
      </template>

      <!-- Column: members -->
      <template #cell(members)="data">
        <span class="text-nowrap">
          
        </span>
        <b-link
          @click="editGroup(data.item)"
          class="font-weight-bold"
        >
          {{ data.item.members.length }} Members
        </b-link>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="editGroup(data.item)"
          />
          <b-tooltip
            title="Edit Group"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-2 cursor-pointer"
            @click="editGroup(data.item)"
          />
          <b-tooltip
            title="View Group members"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
          
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item>
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">Add Appointment</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-b-modal.message-modal
              @click="send(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Send Message</span>
            </b-dropdown-item>
            <b-dropdown-item @click="editGroup(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="delGroup(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalGroups"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <!--  -->
    <b-modal
      id="message-modal"
      :title="`Send Message ${message ? ': '+message.group : ''}`"
      
      cancel-title="Close"
      v-model="isModalActive"
    > 
    <!-- Body -->
    <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
  
            <!-- Group -->
              <b-form-group
                label="Group"
                label-for="group"
              >
                <b-form-input
                  id="group"
                  v-model="message.group"
                  autofocus
                  :disabled="message.group !== ''"
                  trim
                  placeholder="New Group"
                />
  
              </b-form-group>

            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Schedule"
                  label-for="schedule"
                >
                  <!-- <flat-pickr
                    id="schedule"
                    v-model="message.schedule"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d '}"
                    placeholder="YYYY-MM-DD HH:II"
                    /> -->
                    <b-form-datepicker 
                      v-model="message.schedule"
                    ></b-form-datepicker>
                    
                </b-form-group>
              </b-col>
              <b-col sm="6">

                
                  <b-form-group
                    label="Time"
                    label-for="time"
                  >
                    <!-- <flat-pickr
                      id="schedule"
                      v-model="message.schedule"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d '}"
                      placeholder="YYYY-MM-DD HH:II"
                      /> -->
                      <b-form-timepicker 
                        v-model="message.time"
                      ></b-form-timepicker>
                  </b-form-group>
              </b-col>

            </b-row>

            <!-- Message -->
            <validation-provider
                    #default="validationContext"
                    name="Message"
                    rules="required"
                  >
              <b-form-group
                  label="Message"
                  label-for="message"
                >
                  <b-form-textarea
                    id="message"
                    v-model="message.message"
                    trim
                    placeholder="Message"
                    :state="getValidationState(validationContext)"
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="notification small font-weight-bolder">
                
                <b-row>
                    <b-col md="4">
                        <p>Length: <span class="text-primary">{{ message.message.length }}/800 </span></p>
                    </b-col>
                    <b-col md="4">
                        <p>Parts: <span class="text-primary">{{ Math.ceil( message.message.length / 160) }}/5</span></p>
                    </b-col>
                    <b-col md="4">
                        <p>Cost: <span class="text-primary"> {{
                        
                          message.members.length * Math.ceil( message.message.length / 160)

                        }} Sms</span></p>
                    </b-col>
                </b-row>
            </div>

            <b-alert show variant="secondary">
                <div class="alert-body">
                    To send personalised messages, use <span style="cursor: pointer" @click="AddPersonalised('@fullname')" class="text-primary">@fullname</span>  for fullname, <span style="cursor: pointer" @click="AddPersonalised('@firstname')" class="text-primary">@firstname</span> for firstname <span style="cursor: pointer" @click="AddPersonalised('@lastname')" class="text-primary">@lastname</span> for lastname.
                </div>
            </b-alert>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
      </validation-observer>
      <!-- <b-card-text>
        <h5>Check First Paragraph</h5>
        Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
        Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
      </b-card-text> -->
      
    </b-modal>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormTextarea, BFormDatepicker, BFormTimepicker, BForm, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BModal, VBModal, BAlert, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useGroupsList from './useGroupList'
  import flatPickr from 'vue-flatpickr-component'

import groupStoreModule from '../groupStoreModule'
import GroupListAddNew from '../group-add-new/GroupListAddNew.vue'
import memberStoreModule from '../../membership/memberStoreModule'
import smsStoreModule from '../../sms/smsStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BForm,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    VBModal,
    BAlert,
    flatPickr,
    GroupListAddNew,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
    //   message: {
    //     group,
    //     schedule,
    //     time,
    //     message
    //   },
      required,
    }
  },
  setup() {
    // const isAddNewMemberSidebarActive = ref(false)

    const isModalActive = ref(false)

    const blankMessageData = {
        group: '',
        schedule: '',
        time: null,
        message: '',
        members: []
      }

    const message = ref(JSON.parse(JSON.stringify(blankMessageData)))

    const send = (data) => {
      //
      message.value.group = data.group
      message.value.members = data.members
    }

    const GROUP_APP_STORE_MODULE_NAME = 'app-group'
    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
    const SMS_STORE_MODULE_NAME = 'app-sms'

    // Register module
    if (!store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_APP_STORE_MODULE_NAME, groupStoreModule)
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
    if (!store.hasModule(SMS_STORE_MODULE_NAME)) store.registerModule(SMS_STORE_MODULE_NAME, smsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_APP_STORE_MODULE_NAME)
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
      if (store.hasModule(SMS_STORE_MODULE_NAME)) store.unregisterModule(SMS_STORE_MODULE_NAME)
    })

    // const messageLocal = ref(JSON.parse(JSON.stringify(blankMessageData)))
    const resetMessageLocal = () => {
      message.value = JSON.parse(JSON.stringify(blankMessageData))
    }

    const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetMessageLocal, message)

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchGroups,
      getGroups,
      editGroup,
      delGroup,
      fetchMembers,
      tableColumns,
      perPage,
      currentPage,
      totalGroups,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGroupListTable,
      isAddNewMemberSidebarActive,
      clearUserData,
      addGroup,
      updateGroup,

      statusFilter,

      refetchData,

      resolveGroupStatusVariantAndIcon,
      resolveClientAvatarVariant,
      userData,
      onSubmit
    } = useGroupsList(message, resetMessageLocal, isModalActive)

    fetchGroups()

    fetchMembers()

    

    // store
    //   .dispatch('app-group/fetchGroups')
    //   .then(response => {
    //     //
        
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error fetching groups list',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })

    const AddPersonalised = (val) => {
        message.value.message = message.value.message+' '+val
    }


    return {
      getGroups,
      editGroup,
      delGroup,
      tableColumns,
      perPage,
      currentPage,
      totalGroups,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGroupListTable,
      isAddNewMemberSidebarActive,
      clearUserData,
      addGroup,
      updateGroup,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveGroupStatusVariantAndIcon,
      resolveClientAvatarVariant,
      userData,
      getValidationState,
      resetForm,
      clearForm,
      message,
      refFormObserver,
      send,
      AddPersonalised,
      onSubmit,
      resetMessageLocal,
      isModalActive
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
